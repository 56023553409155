<template>
  <el-dialog
    title="服务记录"
    :visible.sync="showMsg"
    width="90%"
    height="80vh"
    top="5vh"
    :show-close="true"
    :close-on-click-modal="true"
    :close-on-press-escape="false"
    :before-close="handleClose"
  >
    <el-table :data="pagedData" border style="width: 100%">
      <el-table-column
        prop="msgSeq"
        label="序号"
        header-align="center"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="msgTime"
        label="时间"
        header-align="center"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="fromAccount"
        label="发言人"
        header-align="center"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="text"
        label="信息内容"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">{{ selectMsg(scope.row) }}</template>
      </el-table-column>
      <el-table-column
        prop="intervalTime"
        label="时长"
        header-align="center"
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="[5, 10, 20]"
      :page-size="pagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="dataList.length"
    />
  </el-dialog>
</template>

<script>
export default {
  props: {
    orderNo: {
      type: String,
      required: true,
    },
    showMsg: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 5,
      },
      pagedData: [], // 存放分页后的数据的数组
    };
  },
  watch: {
    orderNo(val, oldVal) {
      console.log(val, oldVal);
      if (val && val !== oldVal && this.showMsg) {
        this.getDataList();

        this.pagination = {
          currentPage: 1,
          pageSize: 5,
        };
      }
    },
  },
  methods: {
    updatePagedData() {
      const start =
        (this.pagination.currentPage - 1) * this.pagination.pageSize;
      const end = start + this.pagination.pageSize;
      console.log(start, end);
      this.pagedData = this.dataList.slice(start, end);
    },
    handleSizeChange(size) {
      this.pagination.pageSize = size;
      this.updatePagedData();
    },
    handleCurrentChange(page) {
      this.pagination.currentPage = page;
      this.updatePagedData();
    },
    async getDataList() {
      const res = await this.$http.post(
        '/doctor-consultation-order/queryTimList',
        {
          orderNo: this.orderNo,
        }
      );

      this.dataList = res.data.data;
      this.updatePagedData();
    },
    selectMsg({ msgType, text, imageUrl, cloudCustomData }) {
      //             TIMTextElem 取Text
      // TIMImageElem 取ImageUrl
      // TIMCustomElem 取CloudCustomData
      switch (msgType) {
        case 'TIMTextElem':
          return text;
        case 'TIMImageElem':
          return imageUrl;

        default:
          return cloudCustomData;
      }
    },
    handleClose() {
      this.$emit('changeMsgState');
    },
  },
};
</script>

<style></style>
