<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form
      ref="form"
      :inline="true"
      :model="formInline"
      small="size"
      label-width="120px"
    >
      <!-- <el-row>
        <el-col :span="11"> -->
      <el-form-item label="接诊时间：">
        <el-date-picker
          v-model="formInline.visitTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        />
      </el-form-item>
      <!-- </el-col>
        <el-col :span="13/2"> -->
      <el-form-item label="医生姓名：">
        <el-input
          v-model.trim="formInline.doctorName"
          v-check-permission
          placeholder="请输入医生姓名"
        />
      </el-form-item>
      <el-form-item label="下单时间：">
        <el-date-picker
          v-model="formInline.createTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        />
      </el-form-item>
      <!-- </el-col>
      <el-col :span="13/2"> -->
      <el-form-item label="患者姓名：">
        <el-input
          v-model="formInline.patientName"
          v-check-permission
          placeholder="请输入患者姓名"
        />
      </el-form-item>
      <!-- </el-col>
      </el-row>
      <el-row>
        <el-col :span="11"> -->
      <!-- </el-col>
        <el-col :span="13/2"> -->
      <el-form-item label="订单号：">
        <el-input
          v-model="formInline.orderNo"
          v-check-permission
          placeholder="请输入订单号"
        />
      </el-form-item>
      <!-- </el-col>
        <el-col :span="13/2"> -->
      <el-form-item label="问诊类型：">
        <el-select
          v-model="formInline.consultationType"
          placeholder="请选择问诊类型"
          filterable
        >
          <el-option
            v-for="item in consultationTypeAllList"
            :key="item.consultationCode"
            :label="item.consultationName"
            :value="item.consultationCode"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="第三方订单号：">
        <el-input
          v-model="formInline.extend"
          v-check-permission
          placeholder="请输入第三方订单号"
        />
      </el-form-item>
      <el-form-item label="医生团队名称：">
        <el-select v-model="formInline.teamCode" placeholder="请选择医生团队">
          <el-option
            v-for="item in doctorTeamList"
            :key="item.teamCode"
            :value="item.teamCode"
            :label="item.teamName"
          />
        </el-select>
      </el-form-item>
      <!-- </el-col>
      </el-row> -->
      <!-- <el-row>
        <el-col :span="19"> -->
      <div class="statusStyle">
        <div class="orderStatic">
          <el-tag
            v-for="(item, index) in statusList"
            :key="item.orderStatus"
            type="info"
            class="labelStyle"
            :class="{ active: currentIndex === index }"
            @click="selectStatus(index, item.orderStatus)"
          >
            {{ orderStatusList[item.orderStatus] }}({{ item.countNum }})
          </el-tag>
        </div>
        <el-form-item>
          <el-button type="primary" @click="queryData()"> 查询 </el-button>
          <el-button type="primary" @click="queryClear()"> 重置 </el-button>
          <el-button type="primary" @click="exportExcel"> 导出 </el-button>
          <!-- <el-button
            type="primary"
            @click="queryEMR()"
          >
            电子病历
          </el-button> -->
        </el-form-item>
      </div>

      <!-- </el-col>
        <el-col :span="3"> -->
      <!-- <el-form-item>
        <el-button
          type="primary"
          @click="queryData()"
        >
          查询
        </el-button>
        <el-button
          type="primary"
          @click="queryClear()"
        >
          重置
        </el-button>
      </el-form-item> -->
      <!-- </el-col>
      </el-row> -->
      <!-- </el-col> -->
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column
        label="服务订单号"
        prop="orderNo"
        align="center"
        width="140"
      />
      <el-table-column
        label="第三方订单号"
        prop="extend"
        align="center"
        width="140"
      >
        <template slot-scope="scope">
          {{ JSON.parse(scope.row.extend).thirdPartyOrderNo }}
        </template>
      </el-table-column>
      <el-table-column prop="teamName" label="医生团队名称" align="center" />
      <el-table-column prop="deptName" label="科室" align="center" />
      <el-table-column
        prop="doctorName"
        label="医生名称"
        align="center"
        width="100"
      />
      <el-table-column
        prop="consultationType"
        label="问诊类型"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <span>{{ consultationTypeDic[scope.row.consultationType] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="patientName"
        label="患者姓名"
        align="center"
        width="100"
      />
      <el-table-column
        prop="patientMobile"
        label="患者手机号"
        align="center"
        width="100"
      />
      <el-table-column
        prop="orderStatus"
        label="订单业务状态"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <span>{{ orderStatusList[scope.row.orderStatus] }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="首次进线时间"
        align="center"
        width="200"
      />
      <el-table-column
        prop="serviceStartTime"
        label="应答时间"
        width="200"
        align="center"
      />
      <el-table-column
        prop="completeTime"
        label="挂断时间"
        align="center"
        width="200"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.completeTime || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="服务时长" align="center" width="150">
        <template slot-scope="scope">
          <span>{{ queryDuration(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        show-overflow-tooltip
        width="200"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 0)"
          >
            查看
          </el-button>
          <el-button
            v-if="
              (scope.row.orderStatus === 'WAIT_FOR_SERVER' ||
                scope.row.orderStatus === 'COMPLETED' ||
                scope.row.orderStatus === 'PRE_CONSULTATION') &&
              $checkPermission('CONSULTORDERCANCEL', $route) &&
              scope.row.consultationType !== '120'
            "
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 1)"
          >
            退诊
          </el-button>
          <el-button
            v-if="
              (scope.row.orderStatus === 'SERVING' ||
                scope.row.orderStatus === 'COMPLETED') &&
              $checkPermission('CONSULTHISTORY', $route)
            "
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 2)"
          >
            问诊记录
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog title="退诊" :visible.sync="refundDialogVisible" width="30%">
      <el-form
        v-if="refundDialogVisible"
        ref="form1"
        :model="form1"
        :rules="rules"
        label-width="110px"
        @submit.native.prevent
      >
        <h3 style="margin-bottom: 20px; text-align: center">
          确定{{ orderNo }}订单退诊吗？
        </h3>
        <!-- <h4>确定{{ orderNo }}订单退诊吗？</h4> -->
        <el-form-item label="退诊原因:" prop="cancelReason">
          <el-input
            v-model="form1.cancelReason"
            v-check-permission
            placeholder="请输入退诊原因"
            style="width: 80%"
            maxlength="20"
            @keyup.enter.native="saveService()"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refundDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveService()">确定</el-button>
      </span>
    </el-dialog>

    <msgFlow
      :orderNo="orderNo"
      :showMsg="showMsg"
      @changeMsgState="showMsg = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import { orderStatusList } from '@/utils/component/publicList';
import { downloadUrl, getDuration } from '@/utils/index';
import msgFlow from './components/msgFlow';
export default {
  name: 'ServiceManageList',
  components: { msgFlow },
  data() {
    return {
      showMsg: false,
      orderStatusList: orderStatusList, // 订单业务状态
      loading: false,
      consultationType: '', // 退诊的类型
      orderNo: '', // 退诊的订单编码
      consultationOrderNo: '', // 业务订单编码
      currentIndex: 0,
      refundDialogVisible: false, // 退诊弹框显示与否
      refundId: '', // 退诊患者id
      formInline: {
        doctorName: '',
        teamCode: '',
        patientName: '',
        orderNo: '',
        createTime: '',
        consultationType: 'all',
        visitTime: '',
        orderStatus: '',
        extend: '',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      consultationTypeDic: {},
      consultationTypeAllList: [], // 问诊类型列表，包含全部
      orderListData: [], // 服务列表
      statusList: [], // 状态数据
      doctorTeamList: [], // 医生团队
      selectData: [], // 选中集合
      form1: {
        cancelReason: '',
      },
      rules: {
        cancelReason: [
          { required: true, message: '请输入退诊原因', trigger: 'change' },
        ],
      },
    };
  },
  computed: {
    ...mapState('basicDictionary', {
      consultTypeAllList: (state) => state.consultTypeAllList,
    }),
    ...mapState('categoryManage', {
      categoryList: (state) => state.categoryList,
    }),
  },
  mounted() {
    this.getTeamList();
    this.getConsultOrderList();
    this.getStatisticsByStatus();
    this.getAllConsultList();
  },
  methods: {
    ...mapActions('doctorTeam', ['queryList']),
    // 跳转到电子病历页面
    // queryEMR() {
    //   this.$router.push({
    //     path:'/orderManage/electronicMedicalRecord'
    //   });
    // },
    // 查询团队列表
    getTeamList() {
      this.queryList({}).then((res) => {
        const list = res.data;
        list.unshift({
          teamCode: '',
          teamName: '全部',
        });
        this.doctorTeamList = list;
      });
    },
    // 查看问诊小结
    querySummary(params) {
      const param = {
        consultationType: params.consultationType,
        consultationOrderNo: params.consultationOrderNo,
      };
      this.$api.summaryDetail(param).then((res) => {
        // this.summaryDetail = res.data;
        if (res.code === 0) {
          this.summaryDetailList = res.data;
        }
        return;
      });
      // const result = await this.$api.doctorManage.summaryDetail(param);
      // this.summaryDetailList = result.data;
    },

    ...mapActions('doctorManage', [
      'orderPageQuery',
      'countByStatus',
      'consultationOrderCancel',
    ]),
    ...mapActions('basicDictionary', ['consultationTypeList']),
    // 筛选状态
    // 筛选订单状态
    selectStatus(index, val) {
      this.currentIndex = index;
      this.formInline.orderStatus = val === 'all' ? '' : val;
      this.getConsultOrderList();
    },
    // 状态统计
    getStatisticsByStatus() {
      this.statusList = [];
      const params = {
        doctorName: this.formInline.doctorName,
        patientName: this.formInline.patientName,
        orderNo: this.formInline.orderNo,
        orderStatus: this.formInline.orderStatus,
        teamCode: this.formInline.teamCode,
        createStartTime:
          this.formInline.createTime !== '' &&
          this.formInline.createTime !== null
            ? moment(this.formInline.createTime[0]).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            : '',
        createEndTime:
          this.formInline.createTime !== '' &&
          this.formInline.createTime !== null
            ? moment(this.formInline.createTime[1]).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            : '',
        consultationType:
          this.formInline.consultationType === 'all'
            ? ''
            : this.formInline.consultationType,
        visitEndTime:
          this.formInline.visitTime !== '' && this.formInline.visitTime !== null
            ? moment(this.formInline.visitTime[1]).format('YYYY-MM-DD HH:mm:ss')
            : '',
        visitStartTime:
          this.formInline.visitTime !== '' && this.formInline.visitTime !== null
            ? moment(this.formInline.visitTime[0]).format('YYYY-MM-DD HH:mm:ss')
            : '',
        extend: this.formInline.extend,
      };
      this.countByStatus(params).then((res) => {
        var orderStatus = [
          'PRE_CONSULTATION',
          'WAIT_FOR_SERVER',
          'SERVING',
          'COMPLETED',
          'CANCELED',
        ];
        var list = [];
        let middleList = [];
        var sum = 0;
        res.data.forEach((item) => {
          list.push(item.orderStatus);
          sum = sum + item.countNum;
        });
        middleList = [...res.data];
        for (var i = 0; i < orderStatus.length; i++) {
          var a = orderStatus[i];
          var b = list.includes(a);
          if (!b) {
            middleList.push({
              orderStatus: a,
              countNum: 0,
            });
          }
        }
        orderStatus.forEach((item) => {
          middleList.forEach((item1) => {
            if (item === item1.orderStatus) {
              this.statusList.push(item1);
            }
          });
        });
        this.statusList.unshift({
          orderStatus: 'all',
          countNum: sum,
        });
      });
    },
    // 查询所有问诊类型
    async getAllConsultList() {
      await this.consultationTypeList({});
      const consultationTypeDic = {};
      for (var i = 0; i < this.consultTypeAllList.length; i++) {
        consultationTypeDic[this.consultTypeAllList[i].consultationCode] =
          this.consultTypeAllList[i].consultationName;
      }
      this.consultationTypeDic = consultationTypeDic;
      const list = this.consultTypeAllList;
      list.push({
        consultationCode: 'all',
        consultationName: '全部',
      });
      this.consultationTypeAllList = list;
      console.log('打印订单类型列表', this.consultationTypeAllList);
    },
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getConsultOrderList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getConsultOrderList();
    },
    // 重置
    queryClear() {
      this.formInline = {
        // 查询条件
        doctorName: '',
        patientName: '',
        orderNo: '',
        createTime: '',
        consultationType: 'all',
        teamCode: '',
        visitTime: '',
        orderStatus: '',
      };
      this.currentIndex = 0; // 重置服务状态索引
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getConsultOrderList();
      this.getStatisticsByStatus();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getStatisticsByStatus();
      this.getConsultOrderList();
    },
    // 分页查询用户套餐列表
    getConsultOrderList() {
      this.loading = true;
      const params = {
        doctorName: this.formInline.doctorName,
        patientName: this.formInline.patientName,
        orderNo: this.formInline.orderNo,
        teamCode: this.formInline.teamCode,
        extend: this.formInline.extend,
        orderStatus: this.formInline.orderStatus,
        createStartTime:
          this.formInline.createTime !== '' &&
          this.formInline.createTime !== null
            ? moment(this.formInline.createTime[0]).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            : '',
        createEndTime:
          this.formInline.createTime !== '' &&
          this.formInline.createTime !== null
            ? moment(this.formInline.createTime[1]).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            : '',
        consultationType: this.formInline.consultationType,
        visitEndTime:
          this.formInline.visitTime !== '' && this.formInline.visitTime !== null
            ? moment(this.formInline.visitTime[1]).format('YYYY-MM-DD HH:mm:ss')
            : '',
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
        visitStartTime:
          this.formInline.visitTime !== '' && this.formInline.visitTime !== null
            ? moment(this.formInline.visitTime[0]).format('YYYY-MM-DD HH:mm:ss')
            : '',
      };
      if (this.formInline.consultationType === 'all') {
        delete params.consultationType;
      }
      this.orderPageQuery(params)
        .then((res) => {
          this.loading = false;
          this.orderListData = res.data.data;
          // this.orderListData.extend = JSON.parse(this.orderListData.extend);
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
          console.log(res.data.data, ';1111111');
          // this.getStatisticsByStatus()
        })
        .catch(() => {});
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 编辑
     * 2 - 删除
     */
    dataOperation(item, type) {
      if (type === 1) {
        this.refundDialogVisible = true;
        this.consultationType = item.consultationType;
        this.orderNo = item.orderNo;
        this.consultationOrderNo = item.consultationOrderNo;
      } else {
        console.log('查看问诊记录', item);
        this.orderNo = item.orderNo;
        this.showMsg = true;
        // let path = '';
        // if (type === 2) {
        //   path = '/orderManage/consultTextHistory';
        // } else {
        //   switch (item.consultationType) {
        //     case '108':
        //       path = '/orderManage/consultDetail';
        //       break;
        //     case '1':
        //       path = '/orderManage/textConsultDetail';
        //       break;
        //     case '4':
        //     case '401':
        //       path = '/orderManage/videoConsultDetail';
        //       break;
        //     case '120':
        //       path = '/orderManage/furtherConsultation';
        //       break;
        //     default:
        //       break;
        //   }
        // }
        // this.$router.push({
        //   path: path,
        //   query: {
        //     orderDetailNum: item.consultationOrderNo,
        //     type: item.consultationType,
        //     orderNo: item.orderNo,

        //     // info: JSON.stringify(item),
        //     consultationTypeDic: JSON.stringify(this.consultationTypeDic),
        //   },
        // });
      }
    },
    // 确定退诊
    saveService() {
      const param = {
        orderNo: this.orderNo,
        consultationType: this.consultationType,
        consultationOrderNo: this.consultationOrderNo,
        ...this.form1,
      };
      this.$refs['form1'].validate((valid) => {
        if (valid) {
          this.consultationOrderCancel(param).then((res) => {
            if (res.code === 0) {
              this.refundDialogVisible = false;
              this.$message.success('退诊成功');
              setTimeout(this.getConsultOrderList, 2000);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 选中的数据
    handleSelectionChange(val) {
      this.selectData = val;
    },
    // 获取时长
    queryDuration(row) {
      const { serviceStartTime, completeTime } = row;
      return getDuration(serviceStartTime, completeTime);
    },
    // 获取公共参数
    getcommonParams() {
      const params = {
        doctorName: this.formInline.doctorName,
        patientName: this.formInline.patientName,
        orderNo: this.formInline.orderNo,
        teamCode: this.formInline.teamCode,
        extend: this.formInline.extend,
        orderStatus: this.formInline.orderStatus,
        createStartTime:
          this.formInline.createTime !== '' &&
          this.formInline.createTime !== null
            ? moment(this.formInline.createTime[0]).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            : '',
        createEndTime:
          this.formInline.createTime !== '' &&
          this.formInline.createTime !== null
            ? moment(this.formInline.createTime[1]).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            : '',
        consultationType:
          this.formInline.consultationType === 'all'
            ? ''
            : this.formInline.consultationType,
        visitEndTime:
          this.formInline.visitTime !== '' && this.formInline.visitTime !== null
            ? moment(this.formInline.visitTime[1]).format('YYYY-MM-DD HH:mm:ss')
            : '',
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
        visitStartTime:
          this.formInline.visitTime !== '' && this.formInline.visitTime !== null
            ? moment(this.formInline.visitTime[0]).format('YYYY-MM-DD HH:mm:ss')
            : '',
      };
      return params;
    },
    /**
     * @description: 导出
     * @param {*}
     * @return {*}
     */
    exportExcel() {
      this.loading = true;
      const params = this.getcommonParams();
      delete params.page;

      if (this.selectData.length > 0) {
        const orderNoList = [];
        this.selectData.map((item) => {
          orderNoList.push(item.orderNo);
        });
        params.orderNoList = orderNoList;
      }
      this.$api.exportConsultationOrder(params).then((res) => {
        this.loading = false;
        console.log('问诊订单类型导出', res);
        const fileName =
          res.headers['content-disposition']?.split(
            'attachment;filename='
          )?.[1] || '';
        downloadUrl(res.data, decodeURIComponent(fileName));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  margin-right: 50px;
}

.statusStyle {
  display: flex;
  margin-left: 30px;
}

.labelStyle {
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
</style>
